import { FormControl } from "@angular/forms";

export interface IArticulo {
    Codigo: number;
    Descripcion: string;
    CodigoArticulo: string;
    Rubro: number;
    Marca: number;
    Grupo: number;
    OrdenGrupo: number;
    stockMinimo: number;
    imagen: number;
    subsector: number;
    pesoReal: number;
    unidadMedidaStockMin: number;
    activo: number;
    imagenQR: any;
    etiquetado: number;
    puntoPedido?: number;
    stockDeseado: number;
    preetiquetado: number;
    inventariado: number;
    devolucion: number;
    departamento: number;
    notificacion: number;
    verificado: number;
    rubro_descripcion: string;
    marca_descripcion: string;
    sector_descripcion: string;
    subsector_descripcion: string;
    Estado: string;
    check?: FormControl;
    largo_tira?: number;
    imagen_data?: any;
    stock:number;
    articulo_unidad_medidas?:any;
}
export class IArticuloImpPVC {
    Codigo: number;
    Descripcion: string;
    CodigoArticulo: string;
    Rubro: number;
    Marca: number;
    Grupo: number;
    OrdenGrupo: number;
    stockMinimo: number;
    imagen: number;
    subsector: number;
    pesoReal: number;
    unidadMedidaStockMin: number;
    unidadMedidaStockMin_desc?: string;//para importacion
    activo: number;
    imagenQR: any;
    etiquetado: number;
    puntoPedido: number;
    stockDeseado: number;
    preetiquetado: number;
    inventariado: number;
    devolucion: number;
    departamento: number;
    notificacion: number;
    verificado: number;
    rubro_descripcion: string;
    marca_descripcion: string;
    sector_descripcion: string;
    subsector_descripcion: string;
    Estado: string;
    check?: FormControl;
    largo_tira?: number;
}

export interface IArticuloFull {
    Codigo: number;
    CodigoArticulo: string;
    Descripcion: string;
    Rubro: number;
    Grupo: number;
    OrdenGrupo: number;
    stockMinimo: number;
    imagen: number;
    subsector: number;
    pesoReal: number;
    unidadMedidaStockMin: number;
    activo: number;
    imagenQR: any;
    etiquetado: number;
    puntoPedido: number;
    stockDeseado: number;
    stock_data: {
        articulo: number;
        panol: number;
        stock: string;
        ultimo_inventario: string;
        codigoPanol: string;
        panol_descripcion: string;
    }[];
    stock: number;
    preetiquetado: number;
    inventariado: number;
    devolucion: number;
    departamento: number;
    notificacion: number;
    verificado: number;
    largo_tira?: number;
    articulo_unidad_medidas: IUnidadMedida[];
    imagen_data: IImage;
    formCantArt?: FormControl;
    formUM?: FormControl;
    formMarcas?: FormControl;
    asociados: IArticulo[]
}

export interface IArticuloReporte {
    unidades: IUnidadMedida[];
    images: Promise<IImage[]>;
}

export interface IUnidadMedida {
    codigo: number;
    articulo: number;
    unidadMedida: number;
    base: number;
    factor: number;
    unidad_medida_descripcion: string;
}

export interface IImage {
    codigo: number;
    imagen: any;
}

export interface IFilterArticulo {
    sel: number;
    rubro: number;
    sector: number;
    subsector: number;
    dep: number;
    not: number;
    estado?: any;
    activo: any;
    ver: number;
    ordenAlfa:boolean;
    pageSize: number, 
    currentPage: number,
    puntoPedido?: number,
}